.day-slots-list {

    .day-slot {
        border: 1px solid #CED4D9;
        border-bottom: none;
        padding: 17px 20px;
        list-style: none;

        &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        &:last-child {
            border-bottom: 1px solid #CED4D9;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        &:hover {
            border: 1px solid #059DC1;
            background-color: #E6F5F7;

            &+li {
                border-top: none;
            }
        }
    }
}