.student-eval-list {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;

    li {
        padding: 9px 0;
        border-bottom: 1px solid #EBEEF0;

        p {
            margin: 0;
        }

        &:first-child {
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            padding-top: 0;

            .questions-counter {
                display: flex;
                width: 23px;
                height: 23px;
                border-radius: 4px;
                justify-content: center;
                align-items: center;
                margin: 0 0 0 auto;

                &.beginner {
                    background-color: #C02227;
                    color: #FFFFFF;
                }

                &.medium {
                    background-color: rgba(230, 129, 46, 0.1);
                    color: #E6812E;
                }

                &.exam_worthy {
                    background-color: rgba(26, 128, 77, 0.1);
                    color: #1A804D;
                }
            }

            &::before {
                content: "";
                width: 16px;
                height: 16px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 14px;
            }

            &.beginner {
                &::before {
                    background-color: #C02227;
                }
            }

            &.medium {
                &::before {
                    background-color: #E6812E;
                }
            }

            &.exam_worthy {
                &::before {
                    background-color: #1A804D;
                }
            }
        }
    }
}

.ghost-radio-buttons {

    .ant-radio-button-wrapper-checked {
        background: #E6F5F7 !important;
    }
}

.valuate-students-radio-group {
    width: 100%;
    display: flex;
    justify-content: end;

    @media screen and (max-width: 768px) {
        justify-content: center;
    }

    .ant-radio-wrapper {
        height: 36px;
        width: 30%;
        background-color: #F0F2F5;
        border-radius: 4px;
    }

    .ant-radio {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .ant-radio-inner {
            height: 22px;
            width: 22px;
            border-radius: 4px;

            &::after {
                width: 16px;
                height: 16px;
                border-radius: 4px;
                top: 2px;
                left: 2px;
            }
        }
    }

}

.valuate-questions-collapse {

    .ant-collapse-header {
        background-color: #525C66;
        color: #FFFFFF !important;
        border-radius: 4px !important;
    }

    .ant-collapse-content-box {
        padding: 10px;
    }
}

.map-container {
    width: 100%;
    height: 300px;
}

.grey-title-card {
    .ant-card-head {
        background-color: #525C66;
    }
}