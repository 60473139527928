@import '~antd/dist/antd.css';

.App .logo {
    padding: 16px;
    padding-left: 24px;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
}

.custom-header {
    background-color: #FFFFFF;
    padding: 12px 24px;
    box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.08), 0 1px 0 0 #E2E7EA;

    @media screen and (max-width: 991px) {

        .go-back-btn {
            margin-left: 30px;
        }
    }

    @media screen and (max-width: 768px) {
        margin: -8px -8px 24px;
    }
}

.login-layout {
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.login-layout__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 1200px;
    overflow: hidden;
}

.main-content {
    margin-left: 200px;
    flex-direction: column !important;
}

.header-row {
    padding: 16px;

    @media screen and (max-width: 768px) {
        padding: 16px 50px;

        button,
        a {
            width: 100%;
            margin-bottom: 8px;
        }
    }
}

@media screen and (max-width: 767px) {
    .main-content {
        margin-left: 0;
    }
}

.ant-layout {
    flex-direction: row;
}

.ant-table-thead>tr>th {
    border-right: 1px solid #f0f0f0;
}

.clickable-row {
    cursor: pointer;
}

.ant-picker-calendar-header {
    padding-right: 10px;
}

.clickable-row td {
    vertical-align: top;
}

.ant-layout-sider {
    background: #1e1e24;
}

@media screen and (max-width: 767px) {
    .ant-layout-sider {
        position: absolute;
        height: 100vh;
        z-index: 100;
    }

    .ant-layout-sider-zero-width-trigger {
        position: absolute;
        top: 0px;
    }

    .ant-layout-header {
        padding-left: 45px !important;
    }
}

.ant-menu-dark {
    background: #1e1e24 !important;
}

.ant-menu-item {
    border-left: 4px solid transparent;
}

.ant-menu-item-selected {
    background: #282a31 !important;
    border-left: 4px solid #0094c4;
}

.mobile-calendar-list {
    border: 1px solid rgb(201, 201, 201);
    margin-bottom: 2px;
    transition: all 0.2s linear;
}

@media screen and (max-width: 767px) {

    .ant-picker-panels {
        flex-direction: column;
    }

    .mobile-range-picker-btn {
        margin-left: 15px;
    }
}

@media screen and (max-width: 576px) {

    .mobile-range-picker {
        width: 100%;
    }

    .mobile-range-picker-btn {
        margin: 10px 0;
        width: 100%;
    }
}

.btn-green {
    background-color: #3AC700;
    border: unset;
}

.btn-black {
    background-color: #000000;
    color: #FFFFFF;
    border: unset;
    border-radius: 4px;

    &:hover,
    &:active,
    &:focus {
        background-color: #1e1e24;
    }
}

.btn-green:hover,
.btn-green:focus {
    background-color: #37ad14;
    border: unset;
}

/* .btn-green:hover span,
.btn-green:focus span,
.btn-green:active {
    color: #000000;
} */

.valuate-students-radio-group {
    width: 100%;
    display: flex;
    justify-content: center;

    .ant-radio-wrapper {
        height: 36px;
        width: 30%;
        background-color: #F0F2F5;
        border-radius: 4px;
    }

    .ant-radio {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .ant-radio-inner {
            height: 22px;
            width: 22px;
            border-radius: 4px;

            &::after {
                width: 16px;
                height: 16px;
                border-radius: 4px;
                top: 2px;
                left: 2px;
            }
        }
    }

}

.valuate-questions-collapse {

    .ant-collapse-header {
        background-color: #525C66;
        color: #FFFFFF !important;
        border-radius: 4px !important;
    }

    .ant-collapse-content-box {
        padding: 10px;
    }
}

.map-container {
    width: 100%;
    height: 300px;
}

.register-point-btn {
    margin: 20px 0;
    width: 100%;
    background-color: #FA3A00;
    color: #FFFFFF;
    border: none;
    padding: 11px;
    cursor: pointer;
}

.register-point-btn-rnd {
    width: 52px;
    height: 52px;
    background-color: #FA3A00;
    color: #FFFFFF;
    border: none;
    border-radius: 50%;
    padding: 14px;
    cursor: pointer;
    position: fixed;
    right: 15px;
    bottom: 73px;
    z-index: 2;
}

@media print {
    .ant-layout-sider {
        display: none;
    }

    @page {
        size: 210mm 297mm;
        margin: 2cm
    }

    .printable {
        // margin: 60px;
    }

    .ant-card {
        border: unset;
    }

    .ant-card-body {
        padding: 0;
    }

    .ant-page-header {
        display: none;
    }

    .main-content {
        margin-left: 0;
        overflow-x: hidden;
    }

    th,
    td {
        padding: 5px !important;
    }

    .ant-layout {
        display: block;
        margin-bottom: 100px;
        background: white;
    }

    .print-stuff {
        padding-top: 100px;
    }
}